import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';

import NewPropertyForm from '../../components/newPropertyForm/NewPropertyForm';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import PrivateRoute from '../../components/Security/privateRoute';

import { fetchProperty, setProperty } from '@a1-ict/fsbo/propertySlice';
import { selectProperty } from '@a1-ict/fsbo/propertySlice/selectors';

const AddProperty = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const id = params.get('edit');
  const dispatch = useDispatch();
  const property = useSelector(selectProperty);

  useEffect(() => {
    if (id) {
      dispatch(fetchProperty({ id, isEdit: true }));
    }
  }, [id]);

  useEffect(() => {
    if (property.id) dispatch(setProperty({ ...property, is_edit: true }));
  }, [property.id]);

  return (
    <PrivateRoute>
      <DashboardLayout title="Добавяне на обява">
        <Row>
          <NewPropertyForm
            isDashboard={true}
            isBuilding={!!property.is_building}
            isEditing={true}
          ></NewPropertyForm>
        </Row>
      </DashboardLayout>
    </PrivateRoute>
  );
};

export default AddProperty;
